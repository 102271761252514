import React, { useState } from "react";
import { Button } from "react-bootstrap";
import RateModal from "./modal/RateModal";
import useFetchWithRetry from "./services/useFetchWithRetry";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from "moment/moment";
import AddCharge from "./pages/AddCharge";
import CustomButton from "./pages/CustomButton";
import RatesTable from "./pages/RatesTable";
import NoteManager from "./pages/NoteManager";
import {
  sendRecords,
  getAirportData,
  getAirlineData,
} from "./services/api.service";

const AirRatePage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const recordId = parseInt(searchParams.get("record_id"));

  const { data: airports } = useFetchWithRetry(getAirportData);
  const { data: airlines } = useFetchWithRetry(getAirlineData);

  const [msg, setMsg] = useState("");
  const [columnName, setColumnName] = useState("");
  const currentDate = moment().format("YYYY-MM-DD");
  const [showModal, setShowModal] = useState(false);
  const [submittedForm, setSubmittedForm] = useState(false);
  const [submissionNotes, setSubmissionNotes] = useState("");
  const [weightPrices, setWeightPrices] = useState([
    { level: "minimum", value: "" },
    { level: "normal", value: "" },
    { level: "100", value: "" },
    { level: "300", value: "" },
    { level: "500", value: "" },
    { level: "1000", value: "" },
  ]);
  const [records, setRecords] = useState([
    {
      id: uuidv4(),
      from: "",
      to: "",
      airline: "",
      weight: weightPrices,
      maxCapacity: "",
      minTime: "",
      maxTime: "",
      routing: "",
      validityFrom: currentDate,
      validityTo: "",
      comment: "",
    },
  ]);
  const [newRecord, setNewRecord] = useState({
    id: uuidv4(),
    from: "",
    to: "",
    airline: "",
    weight: weightPrices,
    maxCapacity: "",
    minTime: "",
    maxTime: "",
    routing: "",
    validityFrom: currentDate,
    validityTo: "",
    comment: "",
  });
  const [charges, setCharges] = useState([
    {
      id: uuidv4(),
      name: "",
      kgRate: "",
      minRate: "",
      currency: "USD",
      shipmentRate: "",
    },
  ]);
  const [newCharge, setNewCharge] = useState({
    id: uuidv4(),
    name: "",
    kgRate: "",
    minRate: "",
    currency: "USD",
    shipmentRate: "",
  });

  const handleShowModal = () => setShowModal(!showModal);

  const handleAddColumn = (num) => {
    const parsedNum = parseInt(num);
    if (isNaN(parsedNum)) {
      setMsg("Please enter a valid number.");
      return;
    }

    if (parsedNum === 0) {
      setMsg("Entered value can't be 0.");
      return;
    }

    if (weightPrices.some((entry) => entry.level === parsedNum.toString())) {
      setMsg("Entered value already exists.");
      return;
    }

    const newWeightPrices = [...weightPrices, { level: num, value: "" }].sort(
      (a, b) => parseFloat(a.level) - parseFloat(b.level)
    );

    setWeightPrices(newWeightPrices);

    setRecords((currentRecords) =>
      currentRecords.map((record) => ({
        ...record,
        weight: newWeightPrices.map((wp) => {
          const existingWeight = record.weight.find(
            (w) => w.level === wp.level
          );
          return (
            existingWeight || {
              ...wp,
              value: existingWeight ? existingWeight.value : "",
            }
          );
        }),
      }))
    );

    setNewRecord((prevNewRecord) => ({
      ...prevNewRecord,
      weight: newWeightPrices.map((wp) => ({ ...wp, value: "" })),
    }));
    setMsg("");
    setColumnName("");
    handleShowModal();
  };

  const handleAddRecord = () => {
    if (handleRowValidation(records.length - 1) && records.length >= 1) {
      const newRecordToAdd = {
        ...newRecord,
        weight: newRecord.weight.map((wp) => ({ ...wp })),
      };

      setRecords((prevRecords) => [...prevRecords, newRecordToAdd]);

      setNewRecord((prevNewRecord) => ({
        ...prevNewRecord,
        weight: prevNewRecord.weight.map((wp) => ({ ...wp, value: "" })),
      }));

      setMsg("");
    } else if (records.length === 0) {
      const firstRecordToAdd = {
        ...newRecord,
        weight: newRecord.weight.map((wp) => ({ ...wp })),
      };

      setRecords([firstRecordToAdd]);
    }
  };

  const handleAddCharge = () => {
    setCharges((prev) => [...prev, { ...newCharge }]);
    setNewCharge({
      name: "",
      kgRate: "",
      minRate: "",
      currency: "",
      shipmentRate: "",
    });
  };

  const handleSubmit = (records, charges, recordId) => {
    if (handleRowValidation(records.length - 1)) {
      setRecords([
        {
          id: uuidv4(),
          from: "",
          to: "",
          airline: "",
          minimum: "",
          normal: "",
          weight: weightPrices,
          maxCapacity: "",
          minTime: "",
          maxTime: "",
          routing: "",
          validityFrom: "",
          validityTo: "",
          comment: "",
        },
      ]);
      setMsg("success");
      sendRecords(records, charges, submissionNotes, recordId);
      setSubmittedForm(true);
    }
  };

  const handleRowValidation = (index) => {
    if (!records.length) {
      return true;
    } else {
      const emptyField = ["from", "to", "airline", "validity"].find(
        (field) => records[index][field] === ""
      );
      if (emptyField) {
        setMsg(`Please fill the "${emptyField}" field.`);
        return false;
      } else if (!records[index].weight.some((weight) => weight.value !== "")) {
        setMsg(`Please provide at least one weight price field.`);
        return false;
      } else if (records[index].from === records[index].to) {
        setMsg("Airport 'From' and 'To' can't be the same, please update.");
        return false;
      } else if (
        records[index].minTime === "" ||
        records[index].maxTime === ""
      ) {
        setMsg('Please enter a valid "Transit Time" range.');
        return false;
      } else if (
        Number(records[index].minTime) > Number(records[index].maxTime)
      ) {
        setMsg(
          "'Min Transit Time' can't be higher than 'Max Transit Time', please update."
        );
        return false;
      } else if (records[index].routing.length === 0) {
        setMsg("Please enter valid 'Routing'.");
        return false;
      } else if (records[index].validityFrom > records[index].validityTo) {
        setMsg("'Validity From' can't be after 'Validity To', please update.");
        return false;
      } else {
        return true;
      }
    }
  };

  const handleDuplicateRate = (index) => {
    if (!handleRowValidation(index)) return;

    setRecords((prevRecords) => {
      const rateToDuplicate = prevRecords[index];

      const duplicatedRate = {
        ...rateToDuplicate,
        id: uuidv4(),
        weight: rateToDuplicate.weight.map((w) => ({ ...w })),
        routing: rateToDuplicate.routing.map((r) => ({ ...r })),
      };

      const newRecords = [
        ...prevRecords.slice(0, index + 1),
        duplicatedRate,
        ...prevRecords.slice(index + 1),
      ];
      return newRecords;
    });
  };

  return (
    <>
      <RateModal
        showModal={showModal}
        handleShowModal={handleShowModal}
        handleAddColumn={handleAddColumn}
        columnName={columnName}
        setColumnName={setColumnName}
      />
      <div className="card card-custom">
        <div className="card-header py-3 px-3">
          <div className="card-title font-weight-bold font-size-sm mt-2 ml-6">
            <h3>Air Rates</h3>
          </div>
        </div>
        <div className="card-body">
          <CustomButton
            name="Add Weight Column"
            icon="fa fa-plus"
            func={handleShowModal}
            display={submittedForm}
          />
          {msg && (
            <div
              onClick={() => setMsg("")}
              className={`user-select-none alert ${
                msg !== "success" ? "alert-danger" : "alert-primary"
              } text-center fw-bold fs-10`}
            >
              {msg !== "success" ? msg : "Rate submitted successfully."}
            </div>
          )}
          <RatesTable
            weightPrices={weightPrices}
            records={records}
            airports={airports}
            airlines={airlines}
            setRecords={setRecords}
            setMsg={setMsg}
            submittedForm={submittedForm}
            handleDuplicateRate={handleDuplicateRate}
          />
          <CustomButton
            name="Add Rate"
            icon="fa fa-plus"
            func={handleAddRecord}
            display={submittedForm}
          />
          <div
            style={{
              width: "850px",
              display: submittedForm ? "none" : "",
            }}
          >
            <NoteManager setSubmissionNotes={setSubmissionNotes} />
          </div>
          <div style={{ display: submittedForm ? "none" : "" }}>
            <h5>Destination Charges</h5>
            <AddCharge charges={charges} setCharges={setCharges} />
          </div>
          <CustomButton
            name="Add Local Charge"
            icon="fa fa-plus"
            func={handleAddCharge}
            display={submittedForm}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outline-info"
              className="col-md-2"
              form="rate-form"
              style={{
                marginBottom: "1.5em",
                maxWidth: "135px",
                display: submittedForm ? "none" : "",
              }}
              onClick={() => handleSubmit(records, charges, recordId)}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AirRatePage;
